<template>
  <div class="page-container">
    <section class="page-header">
      <div class="page-header__nav">
        <div class="page-header__nav-item">
          1 問答
        </div>
        <div class="page-header__nav-item page-header__nav-item--highlighted">
          2 分析
        </div>
        <div class="page-header__nav-item ">
          3 結果
        </div>
      </div>
    </section>
    <section class="page-content">
      <div class="loading-card">
        <span>分析中 ...</span>
        <vcl-facebook></vcl-facebook>
      </div>
    </section>
  </div>
</template>

<style lang="scss" scoped>
.page-container {
  height: 100vh;
  background: linear-gradient(#535cd9, #2b90cb);
  padding: 25px 25px;
}

.page-header {
  display: flex;
  justify-content: flex-end;
  height: 40px;
  line-height: 40px;

  &__nav {
    display: flex;
    justify-content: flex-end;
  }

  &__nav-item {
    opacity: 0.3;
    margin: 0px 10px;
    font-weight: 400;
  }

  &__nav-item--highlighted {
    opacity: 1;
  }
}

.page-content {
  margin-top: 25vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #414c64;
  background-color: #fff;
  border-radius: 10px;
  width: 360px;
  height: 180px;
  padding: 15px;
}
</style>

<script>
import { VclFacebook } from "vue-content-loading";

export default {
  components: {
    VclFacebook
  }
};
</script>
